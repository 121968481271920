import React from "react";
import TournamentItem from "../../_common/TournamentItem/TournamentItem"
import moment from "moment";
const Trash=()=>{
    var today = new Date()
    const rDiff = moment(today).diff(moment());
    const rDiffDuration = moment.duration(rDiff);
    const _rDate = moment(today);
    let rString = _rDate.format("DD MMM YYYY");
    if (rDiffDuration.days() === -1) rString = "Yesterday";
    if (rDiffDuration.days() === 1) rString = "Tomorrow";
    if (rDiffDuration.days() === 0) rString = "Today";
    let reg_date = rString + " at " + _rDate.format("HH:mm") + " " + moment.tz(moment.tz.guess()).zoneAbbr();
  
return(
    <><div style={{marginLeft:"10px"}}>
   <h1>Trash</h1>
   
        <TournamentItem
            key={"23"}
            link_to={`/tournament/${123}`}
            image={'https://cdn.shopify.com/s/files/1/0418/6429/8657/collections/cat_banner_01e7877d-8e74-4918-8f24-4d034cc4abd4_2048x600_crop_top.jpg?v=1665405073'}
            title="ali"
            subtitle="{subtitle}"
            color="red"
            date={"Starting: " + reg_date }
            reg_date={reg_date ? "Registration: " + reg_date : undefined}
            startTime={reg_date}
            registerationDeadline={reg_date}
        />
                        
   </div>
    </>
)
}
export default Trash;
