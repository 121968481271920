import React, { useContext } from "react";
import { SimplePageWithTabs } from "@common";
import MyAccount from "./SettingsTabs/MyAccount/MyAccount";
import MySocials from "./SettingsTabs/MySocials/MySocials";
import MyPrivacy from "./SettingsTabs/MyPrivacy/MyPrivacy";
import Sound from "./SettingsTabs/Sound/Sound";
import MyPlayersCoaches from "./SettingsTabs/MyPlayersCoaches/MyPlayersCoaches";
import { useHistory, useLocation } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
// import ChangePassword from "../ChangePassword/ChangePassword";
import useTranslated from "../../helpers/translationHelper";

const Settings = () => {
  const history = useHistory();
  const { user } = useContext(AppContext);
  const settingsTrans = useTranslated('id_settings_0')
  const viewProfileTrans = useTranslated('id_settings_1')
  const myAccTrans = useTranslated('id_settings_2')
  const changePassTrans = useTranslated('id_settings_3')
  const socialTrans = useTranslated('id_settings_4')
  const playerandCoachTrans = useTranslated('id_settings_5')
  const privicyTrans = useTranslated('id_settings_6')
  const notificationTrans = useTranslated('id_settings_7')
  const search = useLocation().search;
  const name = new URLSearchParams(search).get("notification");
  const tabs = {
    /* "My Account" */[myAccTrans]: <MyAccount />,
    // /* "Change Password" */[changePassTrans]: <ChangePassword />,
    /* Socials */[socialTrans]: <MySocials />,
    /* "My Players & Coaches" */[playerandCoachTrans]: <MyPlayersCoaches />,
    /* Privacy */[privicyTrans]: <MyPrivacy />,
    /* Notifications */[notificationTrans]: <Sound />,
  };

  return (
    <SimplePageWithTabs
      title={settingsTrans}
      tabs={tabs}
      currentPage={name ? 5 : 0}
      top_right={
        <button className="button bordered" onClick={() => history.push(`/players/${user.state._id}`)}>
          {viewProfileTrans}
        </button>
      }
    />
  );
};

export default Settings;
