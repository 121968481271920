import React, { useState, useContext } from "react";
import { AppContext } from "../../../../context/AppContext";
import Axios from "axios";
import { RiEditLine } from "react-icons/ri";
import { toast } from "react-toastify";
import EditImageModal from "../../../_modals/EditImageModal/EditImageModal";
import useTranslated from "../../../../helpers/translationHelper";

const AvatarCover = () => {
  const { user } = useContext(AppContext);
  const [modals, setModals] = useState({});
  const avatarTrans = useTranslated("id_settings_(my_account)_16")
  const bannerTrans = useTranslated("id_settings_(my_account)_17")
  const updateUser = async (type, imageUrl) => {
    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/auth/image`, { type, image: imageUrl });
      user.dispatch({ type: "update", payload: { [type]: imageUrl } });
      setModals({ ...modals, [type]: false });
    } catch (err) {
      toast.error("There was a problem updating your account");
    }
  };

  const images = [
    { name: `${avatarTrans}`, key: "avatarImage", width: 250, height: 250, displayWidth: 150, displayHeight: 150, crop: true, radius: 250 },
    { name: `${bannerTrans}`, key: "headerImage", width: 800, height: 200, displayWidth: 500, displayHeight: 150, crop: false },
  ];
  
  return (
    <div className="row">
      <div className="col-12" style={{ display: "flex" }}>
        {images && images.map(({ name, key, width, height, displayWidth, displayHeight, crop, radius }) => (
          <React.Fragment key={key}>
            <EditImageModal
              name={name}
              width={width}
              height={height}
              open={modals[key]}
              crop={crop}
              borderRadius={radius}
              onClose={() => setModals({ ...modals, [key]: false })}
              afterImageUpload={(file) => updateUser(key, file)}
            />

            <div className="form-row" style={{ marginRight: 30 }}>
              <label htmlFor="Avatar">{name}</label>
              <div
                className="upload-image-btn"
                style={{ backgroundImage: `url(${user.state[key]})`, width: displayWidth, height: displayHeight }}
                onClick={() => setModals({ ...modals, [key]: true })}
              >
                <RiEditLine />
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default AvatarCover;
